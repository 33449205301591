html {
    font-family: system-ui, sans-serif;
    font-size: 1.25rem;
  }
  
  body {
    margin: 0;
    align-items: center;
    justify-content: center;
    display: flex;
 
    background-color: #fff;


  }

 
  
  path {
    fill: #2a354d;
  }
  
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  